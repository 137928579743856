.modal {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;

  .modal-content {
    max-width: 500px;
    padding: 10px;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: center;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    transition: all 0.5s;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 40px;

    &.dash-modal {
      padding: 0;
      border-radius: 3px;
      text-align: left;

      * {
        pointer-events: none;
      }
    }

    .dell-group {
      display: block;

      .form-group {
        text-align: left;
        display: flex;
        align-items: center;

        label {
          display: inline-block;

          &.label-icon {
            order: 1;
            min-width: 20px !important;
          }

          &.label-text {
            order: 2;
          }
        }
      }
    }

    .close-btn {
      text-align: right;
      padding: 5px 0;

      button {
        background-color: transparent;
        border: none;
        font-weight: bold;
        color: #000000;
        cursor: pointer;
        font-size: 18px;
      }
    }

    .mod-item {
      h3 {
        font-weight: bold;
        font-size: 18px;
        padding: 5px 10px;
        background-color: #f1f1f1;
        color: #a5a5a5;
      }

      p {
        font-size: 17px;
        padding: 5px 10px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;

        span {
          flex: 0 0 50%;
          max-width: 50%;

          pre {
            font-family: sans-serif;
          }
        }
      }

      .repeatOn {
        padding: 5px 10px;

        .repeatTypeWrapper,
        .monthWrapper {
          text-align: center;
          padding: 5px 0;

          select {
            padding: 5px;
            font-size: 16px;
            border: none;
            border-bottom: 1px dotted black;
            background-color: #f9f9f9;
          }
        }

        .monthWrapper {
          padding: 10px 0;
          text-align: center;
        }

        .DatePickerWrapper {
          text-align: center;

          td {
            padding: 5px 10px;
          }
        }
      }

      &.disabledRepiat {
        pointer-events: none !important;
        opacity: 0.4;
      }

      .repiatToggle {
        border: 1px solid #09b1e7;
        border-radius: 5px;
        display: inline-block;
        padding: 2px;
        margin: 4px 8px;
        cursor: pointer;
      }
    }

    .modal-text {
      padding-bottom: 20px;
      border-bottom: 1px solid #dddddd;

      h2 {
        font-size: 18px;
      }

      p {
        font-size: 15px;
        text-align: center;
      }
    }

    .signin {
      text-align: right;

      a {
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        text-decoration: none;
      }
    }

    .modal-form {
      padding: 5px 10px;
      position: relative;
    }

    .modal-btn {
      padding-top: 10px;
      display: flex;

      button {
        color: #1182fd;
        font-size: 18px;
        text-transform: uppercase;
        margin: 8px 0;
        padding: 0;
        flex: 0 0 50%;
        border: none;
        cursor: pointer;
        background-color: transparent;
      }
    }

    .modal-form-group {
      padding: 5px 10px;
      position: relative;

      .char-count {
        font-size: 10px;
      }

      textarea {
        width: 100%;
        height: 100px;
        resize: none;
        border: 1px solid #606948;
        background-color: transparent;
        border-radius: 3px;
        padding: 5px;
        padding-right: 25px;

        &.error {
          border-color: #ff0000;
        }
      }

      .notification {
        span {
          top: 30px;
          bottom: auto;
          margin-left: -35px;
        }

        p {
          margin-left: -40px;
          margin-bottom: -10px;
        }
      }
    }

    .dash-modal-btn {
      padding-bottom: 0;

      ul {
        margin: 0;

        li {
          padding: 0;
        }
      }
    }
  }

  &.listied-modal {
    .modal-content {
      width: 400px;
    }
  }

  /* &.modal-pass {
    .modal-content {
      max-width: 600px;
      border-radius: 3px;

      .form-group {
        display: flex;
        align-items: center;

        input {
          width: auto;
          flex: 0 0 55%;
        }

        label {
          flex: 0 0 45%;
          cursor: pointer;
          text-align: left;
        }

        &:first-child {
          margin-top: 10px;
        }
      }
    }
  }*/

  &.modal-alert {
    .modal-content {
      .modal-btn {
        button {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }

  &.auth-modal {
    z-index: 999;
    background-color: rgba(80, 84, 97, 0.7);

    .modal-content {
      border-radius: 0;
      width: 640px;
      position: relative;
      top:15%;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);

      .auth-container {
        width: 310px;
        margin: 0 auto;

        .close-auth-modal {
          position: absolute;
          bottom: 100%;
          right: 0;
          margin-bottom: 5px;

          button {
            cursor: pointer;
            outline: none;
            border: none;
            background-color: transparent;
          }
        }

        .error-message {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          background-color: rgba(241, 181, 181, 0.7);
          margin-bottom: 0;
          overflow: hidden;
          opacity: 0;
          max-height: 0;
          transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);

          span {
            flex: 0 0 10%;
            display: block;
            cursor: pointer;

            img {
              vertical-align: middle;
            }
          }

          p {
            text-align: left;
            flex: 0 0 75%;
            font-size: 14px;
            font-weight: bold;
            color: #63626f;
          }

          &.active {
            padding: 15px;
            max-height: 100px;
            opacity: 1;
            margin-bottom: 13px;

            p {
              padding: 0;
            }
          }
        }

        .form-group {
          text-align: left;
          margin-bottom: 20px;

          label {
            display: block;
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 5px;
            color: #63626f;
            text-transform: uppercase;
          }

          input {
            width: 100%;
            padding: 10px;
            padding-right: 20px;
            border: 1px solid #63626f;
            border-radius: 4px;
            background-color: #fafafa;
          }

          .rightLength {
            font-size: 11px;
            float: right;

            font-family: monospace;
          }

          .not-icon {
            top: 20px;
            right: 5px;
          }

          .notification {
            p {
              margin-bottom: -20px;
            }
          }
        }

        .signin {
          padding-bottom: 20px;

          button {
            display: block;
            width: 100%;
            text-align: center;
            outline: none;
            border-radius: 4px;
            background-color: #9ad308;
            cursor: pointer;
            font-size: 13px;
            font-weight: bold;
            color: #ffffff;
            padding: 15px 10;
            text-transform: uppercase;
            border: none;
            line-height: 8px;
          }
        }

        .forgot-password {
          text-align: center;

          button {
            font-weight: bold;
            color: #4a90e2;
            font-size: 12px;
            border: none;
            background-color: transparent;
            outline: none;
            cursor: pointer;
          }
        }
      }

      .log-btns {
        padding-top: 30px;

        ul {
          display: flex;
          justify-content: flex-end;

          li {
            list-style: none;
            padding-left: 10px;

            a,
            button {
              min-width: 100px;
              border: 1px solid #9ad308;
              background-color: transparent;
              color: #313234;
              padding: 5px 0;
              font-size: 14px;
              cursor: pointer;
              outline: none;
              text-align: center;
              border-radius: 3px;
              display: inline-block;
            }

            a {
              text-decoration: none;
            }

            button {
              cursor: pointer;
              outline: none;
              height: 100%;
            }
          }
        }
      }
    }
  }

  &.calendly {
    .modal-content {
      width: 90%;
      max-width: 600px;
      height: 80%;

      .auth-container {
        width: 100%;
        height: 100%;
        padding: 0 20px;

        #schedule_form {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.members-modal,
  &.coach-create-modal {
    .modal-content {
      width: 500px;
      max-width: 500px;
      border-radius: 4px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
      padding-bottom: 20px;

      .modal-title {
        h3 {
          font-size: 20px;
        }
      }

      .form-group {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          flex: 0 0 30%;
          max-width: 30%;
          font-weight: bold;
          text-align: left;
        }

        .input-div {
          flex: 0 0 65%;
          max-width: 65%;

          input {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
          }
        }

        input,
        textarea {
          flex: 0 0 65%;
          max-width: 65%;
          padding: 8px;
          padding-right: 15px;
          border: 1px solid #63626f;
          border-radius: 4px;
          background-color: #fafafa;

          &.error {
            border-color: #ff0000;
          }
        }
      }

      .save-btn {
        width: 100px;
        margin: 0 auto;
        margin-top: 15px;

        button {
          display: block;
          width: 100%;
          text-align: center;
          outline: none;
          border-radius: 4px;
          background-color: #9ad308;
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
          padding: 5px 0;
          text-transform: uppercase;
          border: none;
        }
      }
    }
  }

  &.coach-create-modal {
    .modal-content {
      width: 550px;
      max-width: 550px;
      border-radius: 4px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
      padding: 10px 30px 20px;
    }

    .staff-div-container {
      display: flex;
      justify-content: space-between;
    }

    .flex-group {
      width: 100%;

      .field-flex-wrapper {
        width: 100%;

        .error {
          button {
            border-color: #ff0000;
          }
        }
      }
    }

    .flex-col-40 {
      flex-direction: column;
      padding: 0 !important;
    }

    .form-group {
      flex-direction: column;
      align-items: baseline;

      input[type="text"],
      input[type="password"] {
        flex: none !important;
        max-width: 100% !important;
        height: 31px;
        width: 100%;
      }

      label {
        flex: none !important;
        max-width: 100% !important;
        text-align: left !important;
        width: 100%;
      }
    }
  }

  &.coach-create-modal {
    .error-message {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      background-color: rgba(241, 181, 181, 0.7);
      margin-bottom: 0;
      overflow: hidden;
      opacity: 0;
      max-height: 0;
      transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);

      span {
        flex: 0 0 10%;
        display: block;
        cursor: pointer;

        img {
          vertical-align: middle;
        }
      }

      p {
        text-align: left;
        flex: 0 0 88%;
        font-size: 14px;
        font-weight: bold;
        color: #63626f;
      }

      &.active {
        padding: 15px;
        max-height: 100px;
        opacity: 1;
        margin-bottom: 13px;
        margin-top: 10px;

        p {
          padding: 0;
        }
      }
    }
  }

  &.req-modal {
    .modal-content {
      padding: 30px 0;
    }

    &.active {
      .modal-content {
        input {
          &.error {
            border-color: #ff0000;
          }
        }
      }
    }
  }

  &.stripe-modal {
    .modal-content {
      max-width: 500px;
      min-width: 500px;
      font-size: 16px;
    }
    .modal-content-plans{
      max-width: 540px;
    }
  }

  &.lessons-modal {
    .modal-content {
      width: 100%;
      max-width: 80%;
      position: relative;

      .form-group {
        label {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .stripe-modal {
      .modal-content {
        max-width: 350px;
      }
    }
  }

  &.enlarge-for-update {
    .modal-content {
      width: 100%;
      max-width: 450px;
      position: relative;
      display: flex;

      .form-group {
        label {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  &.innerLesson {
    .modal-content {
      width: 300px;

      .form-group {
        margin-top: 20px !important;
      }
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;

    .modal-content {
      &.dash-modal {
        * {
          pointer-events: inherit;
        }
      }
    }
  }
}

.coachSelect {
  &.form-group {
    margin-top: 0;
  }

  label {
    font-weight: bold;
    font-size: 17px;
    line-height: 31px;
  }

  select {
    float: right;
  }

  //min-height: 41px;
}

.weekdaysWrapper {
  padding: 10px 0;
  text-align: center;

  .weekdays {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid black;
    width: 30px;
    padding: auto 0;
    line-height: 30px;
    height: 30px;
    text-align: center !important;
    transition: all 0.3s;
    margin-left: 5px;
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: #a5a5a5;
    }

    &.selected {
      background-color: #9ad308;
      border: 1px solid #9ad308;
      color: #fff;
    }
  }
}
